import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { artistType } from '../types';

import slugify from '../util/slugify';

const ArtistOverview = ({ artists }) => (
  <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-6">
    {artists.map((artist) => (
      <Link
        key={artist.strapiId}
        to={`/artists/${slugify(artist.Name)}`}
        className="select-none bg-lighter-bg p-2 lg:p-4 rounded lg:rounded-xl hover:bg-white hover:bg-opacity-10 focus:ring focus:ring-gray-500 focus:outline-none"
      >
        <div>
          <h3 className="text-lg lg:text-2xl font-bold text-gray-100 mb-3">
            {artist.Name}
          </h3>
          <GatsbyImage
            className="object-cover max-h-64 rounded pointer-events-none"
            image={artist.CoverImage.localFile.childImageSharp.gatsbyImageData}
            alt={`${artist.Name} cover`}
          />
        </div>
      </Link>
    ))}
  </div>
);

ArtistOverview.propTypes = {
  artists: PropTypes.arrayOf(artistType).isRequired,
};

export default ArtistOverview;
