/* eslint-disable react/prop-types */
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import ArtistOverview from '../components/artistOverview';
import { PageH2 } from '../components/headers';
import Layout from '../components/layout';
import ReleasesDisplay from '../components/release-display/releasesDisplay';
import SEO from '../components/seo';
import arrayHasValues from '../util/arrayHasValues';

export const query = graphql`
  {
    allStrapiArtists(sort: { fields: Name }) {
      nodes {
        Description
        Name
        strapiId
        CoverImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    upcomingReleases: allStrapiReleases(
      filter: { isFuture: { eq: true } }
      sort: { fields: ReleaseDate, order: ASC }
      limit: 6
    ) {
      nodes {
        id
        Title
        ReleaseDate(formatString: "YYYY-MM-DD")
        Type
        artists {
          Name
        }
        Cover {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    lastReleases: allStrapiReleases(
      filter: { isFuture: { eq: false } }
      sort: { fields: ReleaseDate, order: DESC }
      limit: 6
    ) {
      nodes {
        id
        Title
        ReleaseDate(formatString: "YYYY")
        Type
        artists {
          Name
        }
        Cover {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const artists = data.allStrapiArtists.nodes;
  const lastReleases = data.lastReleases.nodes;
  const upcomingReleases = data.upcomingReleases.nodes;

  return (
    <Layout>
      <SEO title="Home" />
      <div className="mx-4 lg:mx-12 pb-12 lg:pb-24">
        {arrayHasValues(lastReleases) && (
          <div>
            <div className="flex justify-between">
              <PageH2>Latest Releases</PageH2>
              <Link
                to="/releases"
                className="text-xs sm:text-sm md:text-base lg:text-lg text-trueGray-400 font-semibold  mt-auto pb-7 lg:pb-6 hover:text-trueGray-200"
              >
                Browse all releases<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
            <div className="">
              <ReleasesDisplay releases={lastReleases} artists />
            </div>
          </div>
        )}
        {arrayHasValues(upcomingReleases) && (
          <div>
            <div className="flex justify-between">
              <PageH2>Upcoming Releases</PageH2>
              <Link
                to="/upcoming-releases"
                className="text-xs sm:text-sm md:text-base lg:text-lg text-trueGray-400 font-semibold  mt-auto pb-7 lg:pb-6 hover:text-trueGray-200"
              >
                Browse all upcoming releases
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
            <div className="">
              <ReleasesDisplay releases={upcomingReleases} artists />
            </div>
          </div>
        )}
        <div>
          <PageH2>Artists</PageH2>
          <ArtistOverview artists={artists} />
        </div>
      </div>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    allStrapiArtists: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          Description: PropTypes.string.isRequired,
          Name: PropTypes.string.isRequired,
          strapiId: PropTypes.number.isRequired,
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export default IndexPage;
